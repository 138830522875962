import { Component } from '@angular/core';

@Component({
  selector: 'ds-root',
  template: '<router-outlet></router-outlet>',
  styles: [],
})
export class AppComponent {
  loginTitle = 'login';
}
