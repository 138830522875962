import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { filter, take, retry } from 'rxjs/operators';
import { AuthService } from '@ds/api-services';
import { environment } from '../environments/environment';

const tokenRegex = /^[0-9]{3}-[0-9]{3}$/;
// tslint:disable-next-line
const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /[0-9]{10}/g;

export function tokenValidator(control: AbstractControl): {
  [key: string]: any;
} {
  const isValid = control.value.length === 6;
  return !isValid ? { badToken: { value: control.value } } : null;
}

export function emailValidator(control: AbstractControl): {
  [key: string]: any;
} {
  const isValid =
    emailRegex.test(control.value) || phoneRegex.test(control.value);
  return !isValid ? { badEmail: { value: control.value } } : null;
}

const STEPS = {
  EMAIL: 0,
  TOKEN: 1,
  SUCCESS: 2,
};

@Component({
  selector: 'ds-login',
  template: `
    <div class="login-container">
      <mat-card>
        <mat-card-title>Please login to continue</mat-card-title>
        <mat-vertical-stepper linear #stepper>
          <mat-step label="Request Login Token">
            <form [formGroup]="emailForm" novalidate>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Email Address or Phone Number"
                  inputmode="email"
                  class="email-input"
                  formControlName="emailAddress"
                  appAutoFocus
                  #emailInput
                />
                <mat-error>
                  Valid Email (apple@fruit.com) or Phone (555555555) required
                </mat-error>
              </mat-form-field>
              <div style="margin-top: 5px;">
                <small>Phone Numbers must be pre-registered</small>
              </div>
              <div class="action-buttons">
                <button
                  mat-button
                  [disabled]="isSubmitting || emailForm.invalid"
                  class="email-submit-button"
                  (click)="requestToken()"
                >
                  Request Token
                </button>
                <div *ngIf="errorMessage" class="error-message-container">
                  <span class="error-message"> {{ errorMessage }}</span>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step label="Submit Login Token" [stepControl]="tokenForm">
            <p>
              You should receive a 6 digit token via email. Please allow time
              for it to arrive. Enter it below to login.
              <a (click)="goToStep(0)">Click here to resend token request</a>
            </p>
            <form [formGroup]="tokenForm" novalidate>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Token"
                  type="input"
                  class="token-input"
                  formControlName="token"
                  #tokenInput
                />
                <mat-error> Valid 6 digit token required </mat-error>
              </mat-form-field>
              <div class="action-buttons">
                <button
                  mat-button
                  [disabled]="isSubmitting || tokenForm.get('token').invalid"
                  class="email-submit-button token-submit-button"
                  (click)="submitToken()"
                >
                  Submit Token
                </button>
                <div *ngIf="errorMessage" class="error-message-container">
                  <span class="error-message"> {{ errorMessage }}</span>
                </div>
              </div>
              <div *ngIf="true">
                <p>
                  If you see "INVALID SESSION": A recent update might require to
                  clear your cookies and then refresh if you are having,
                  trouble. Check out this video for help:
                  <a href="/assets/images/remove_cookies.gif" targe="_blank"
                    >Click Here For Video</a
                  >
                </p>
              </div>
            </form>
          </mat-step>
          <mat-step label="Login Success" editable="false">
            <p class="success-text">
              You have been successfully logged in. You will be redirected to
              the app.
            </p>

            <p></p
          ></mat-step>
        </mat-vertical-stepper>
      </mat-card>
    </div>
  `,
  styles: [
    `
      .login-container {
        width: 600px;
        max-width: 95%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
      }
      mat-card {
        width: 100%;
      }
      mat-form-field {
        width: 100%;
      }
      .action-buttons {
        margin-top: 10px;
        display: flex;
        align-items: center;
      }
      .error-message-container {
        margin-left: 15px;
      }
      .error-message {
        color: red;
      }
      a {
        color: blue;
        cursor: pointer;
      }
    `,
  ],
})
export class LoginComponent implements AfterViewInit {
  isSubmitting = false;
  errorMessage: string = null;
  emailAddress: string = null;
  token: string = null;
  @ViewChild('stepper', { static: true }) stepper: CdkStepper;
  @ViewChild('emailInput', { static: true }) emailInput;
  @ViewChild('tokenInput', { static: true }) tokenInput;

  emailForm: FormGroup;
  tokenForm: FormGroup;

  redirect: string;
  defaultRedirect = window.location.hostname.includes('staff.')
    ? '/'
    : environment.defaultRedirect;

  constructor(
    private authSvc: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {
    this.emailForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, emailValidator]],
    });
    this.tokenForm = this.formBuilder.group({
      token: ['', [Validators.required, tokenValidator]],
      isLoggedIn: ['', Validators.required],
    });
    this.redirect = this.route.snapshot.queryParamMap.get('redirect');
  }

  ngAfterViewInit(): void {
    this.authSvc
      .isLoggedIn()
      .pipe(filter(Boolean), take(1))
      .subscribe(() => {
        // turn off linear stepper so we can skip to the end
        this.stepper.linear = false;
        this.goToStep(STEPS.SUCCESS);
        this.cd.detectChanges();
      });

    this.stepper.selectionChange.subscribe((value: StepperSelectionEvent) => {
      if (value.selectedIndex === STEPS.SUCCESS) {
        this.goToApp();
      }
      this.focusInput(value.selectedIndex);
    });
    this.focusInput(STEPS.EMAIL);
  }

  goToApp(): void {
    setTimeout(() => {
      window.location.href = this.redirect || this.defaultRedirect;
    }, 1000);
  }

  focusInput(index: number): void {
    const el = index
      ? this.tokenInput.nativeElement
      : this.emailInput.nativeElement;

    setTimeout(() => {
      el.focus();
      // el.setSelectionRange(0, 0);
    }, 500);
  }

  resetErrorMessage(): void {
    this.errorMessage = null;
  }

  goToStep(step: number) {
    this.stepper.selectedIndex = step;
  }

  requestToken() {
    this.resetErrorMessage();

    const addr = this.emailForm.get('emailAddress').value as string;

    this.isSubmitting = true;

    let props;
    if (emailRegex.test(addr)) {
      props = { emailAddress: addr };
    } else {
      props = { smsNumber: addr };
    }

    this.authSvc.requestToken(props).subscribe((response) => {
      this.isSubmitting = false;
      if (!response.success) {
        this.errorMessage = response.message;
        return;
      }
      this.stepper.next();
      this.tokenInput.nativeElement.focus();
    });
  }

  submitToken(): void {
    this.resetErrorMessage();

    this.isSubmitting = true;

    const preToken = this.tokenForm.get('token').value;
    const token = preToken.split('-').join('');

    let retryCount = 0;

    this.authSvc.submitToken(token).subscribe((response) => {
      this.isSubmitting = false;
      if (!response.success) {
        if (retryCount === 0) {
          retryCount++;
          this.submitToken();
          return;
        }
        this.errorMessage = response.message;
        return;
      }
      this.tokenForm.get('isLoggedIn').setValue(true);
      this.stepper.next();
    });
  }
}
